<script setup>
const props = defineProps(["filter", "filtersStore"]);
const layoutStore = useLayoutStore();
const removeDots = (value) => {
  if (!value) return 0;
  if (value == "") return 0;
  return typeof value == 'string' ? value.replace(/\./g, "") : value;
};

const customValue = ref({
  min: removeDots(
    props.filtersStore.selected_filters[props.filter.slug]?.value?.min
  ),
  max: removeDots(
    props.filtersStore.selected_filters[props.filter.slug]?.value?.max
  ),
});

watch(
  () => props.filtersStore.selected_filters[props.filter.slug],
  () => {
    customValue.value = {
      min: props.filtersStore.selected_filters[props.filter.slug]?.value?.min || '',
      max: props.filtersStore.selected_filters[props.filter.slug]?.value?.max || '',
    };
  }
);

const setValue = (field) => {
  if (customValue.value[field] == 0) customValue.value[field] = "";
  let newValue = JSON.parse(JSON.stringify(customValue.value));
  newValue[field] = customValue[field];
  props.filtersStore.updateValue(
    props.filter,
    JSON.parse(JSON.stringify(newValue))
  );
};
</script>

<template>
  <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter.name }}</h3>
  <div class="flex items-center gap-3 mt-2">
    <input type="number" class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
      @change="setValue($event, 'min')" v-model="customValue.min" />
    <span>to</span>
    <input type="number" class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
      @change="setValue($event, 'max')" v-model="customValue.max" />
  </div>
</template>
